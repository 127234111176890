import * as actions from "../utils/action";

const intitialState = {
  reports: [],
};

const reportsReducer = (state, action) => {
  state = state || intitialState;

  switch (action.type) {
    case actions.SET_REPORTS: {
      return Object.assign({}, state, {
        ...state,
        reports: action.payload,
      });
    }

    case actions.SET_REPORTS_Filters: {
      return Object.assign({}, state, {
        ...state,
        filters: action.payload,
      });
    }

    case actions.SET_REPORTS_ALL: {
      return Object.assign({}, state, {
        ...state,
        allReports: action.payload,
      });
    }

    case actions.SET_USER_INFO: {
      return Object.assign({}, state, {
        ...state,
        userInfo: action.payload,
      });
    }

    case actions.SET_CASE_TYPE: {
      return Object.assign({}, state, {
        ...state,
        caseTypes: action.payload,
      });
    }
    case actions.SET_PROVIDERS: {
      return Object.assign({}, state, {
        ...state,
        providers: action.payload,
      });
    }
    case actions.SET_DOCTORS: {
      return Object.assign({}, state, {
        ...state,
        doctors: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reportsReducer;
