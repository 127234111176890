export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_LOGOUT = "LOGOUT";
export const SET_CLIENT_DETAIL = "SET_CLIENT_DETAIL";
export const SET_CLIENT_RESET = "SET_CLIENT_RESET";

export const LOADER_START = "LOADER_START";
export const LOADER_STOP = "LOADER_STOP";

export const SET_REPORTS = "SET_REPORTS";

export const SET_REFERRAL = "SET_REFERRAL";
export const ADD_REFERRAL = "ADD_REFERRAL";
export const UPDATE_REFERRAL = "UPDATE_REFERRAL";
export const SET_Referral_FILTERS = "SET_Referral_FILTERS";

export const SET_REPORTS_ALL = "SET_REPORTS_ALL";
export const SET_REPORTS_Filters = "SET_REPORTS_Filters";

export const SET_USER_INFO = "SET_USER_INFO";

export const SET_CASE_TYPE = "SET_CASE_TYPE";
export const SET_PROVIDERS = "SET_PROVIDERS";
export const SET_DOCTOR_PROVIDERS = "SET_DOCTOR_PROVIDERS";

export const SET_DOCTORS = "SET_DOCTORS";

export const SET_SUMMERY_REPORTS = "SET_SUMMERY_REPORTS";
