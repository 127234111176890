import { useState } from "react";
const Loader = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [loadingLoader, setLoading] = useState(
    props.loaderProp ? props.loaderProp : false
  );

  return (
    <>
      {loadingLoader ? (
        <div className="dimmer active">
          {" "}
          <div className="lds-ring" style={{ margin: " 294px auto" }}>
            {" "}
            <div /> <div /> <div /> <div />{" "}
          </div>{" "}
        </div>
      ) : null}
    </>
  );
};

export default Loader;
