import React, { useState } from "react";
import { lazy, Suspense, useCallback, useEffect } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import ProtectedRoute from "./routes/ProtectedRoutes";
import "./services/interceptor";
import PublicRoute from "./routes/PublicRoutes";
import * as storage from "./services/storage";
import * as constant from "./utils/constant";
import * as action from "./utils/action";
import Notification from "./components/shared/Notification";
import { Loading } from "./components/shared";
import { history } from "./store/history";

const Login = lazy(() => import("./views/auth/login"));
const Reports = lazy(() => import("./views/reports"));
const Summeryreports = lazy(() => import("./views/summery-reports"));
const Reffreal = lazy(() => import("./views/referral"));
const UserSettings = lazy(() => import("./views/userSettings"));

function App() {
  // const navigate = useNavigate() ;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkUser = useCallback(() => {
    const checkUserIsLoginOrNot = () => {
      const token = storage.getLocalStorage(constant.STORAGE_TOKEN_KEY);

      if (token) {
        const commonServiceToken = storage.getLocalStorage(
          constant.COMMON_SERVICE_AUTH_TOKKEN
        );
        const profile = storage.getLocalStorage(constant.STORAGE_PROFILE_KEY);
        store.dispatch({
          isAuthenticated: true,
          type: action.LOGIN_SUCCESS,
          payload: {
            profile: profile,
            token: token,
            commonServiceToken: commonServiceToken,
          },
        });
      }
    };
    checkUserIsLoginOrNot();
  });

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  return (
    <div className="App">
      <Provider store={store}>
        <Router basename="/" history={history}>
          <Notification></Notification>
          {loading ? (
            <Loading loaderProp={loading} />
          ) : (
            <Suspense fallback={<Loading loaderProp={loading} />}>
              <Routes>
                <Route
                  exact
                  path="/login"
                  element={
                    <PublicRoute>
                      <Login />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/reports/prr/page/:id"
                  element={
                    <ProtectedRoute>
                      <Reports />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reports/prsr/page/:id"
                  element={
                    <ProtectedRoute>
                      <Summeryreports />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/referral/page/:id"
                  element={
                    <ProtectedRoute>
                      <Reffreal />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <UserSettings />
                    </ProtectedRoute>
                  }
                />
                <Route path="/" element={<Navigate to={"/login"} />} />

                <Route
                  path="*"
                  element={<Navigate to={"/reports/prr/page/1"} />}
                />
              </Routes>
            </Suspense>
          )}
        </Router>
      </Provider>
    </div>
  );
}

export default App;
