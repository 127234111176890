import * as actions from "../utils/action";

const intitialState = {
  isAuthenticated: false,
  token: null,
  profile: null,
  commonServiceToken: "",
};

const authReducer = (state, action) => {
  state = state || intitialState;

  switch (action.type) {
    case actions.LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        profile: action.payload.profile,
        commonServiceToken: action.payload.commonServiceToken,
      });
    }
    case actions.LOGIN_LOGOUT: {
      return Object.assign({}, state, {
        ...state,
        isAuthenticated: false,
        token: null,
        profile: null,
      });
    }

    case actions.UPDATE_PROFILE: {
      return Object.assign({}, state, {
        ...state,
        profile: action.payload.profile,
      });
    }

    case actions.LOGIN_FAIL: {
      return Object.assign({}, state, {
        ...state,
        isAuthenticated: false,
        token: null,
        profile: null,
        commonServiceToken: "",
      });
    }
    default:
      return state;
  }
};

export default authReducer;
