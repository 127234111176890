import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLocalStorage } from "./../services/storage";

const ProtectedRoute = ({ children }) => {

  const profile = getLocalStorage("_profile");
  const token = getLocalStorage("_token");
  const auth = useSelector((state) => state.auth);

  if (!auth.isAuthenticated || (profile === null && token === null)) {
    return <Navigate to="/login" replace></Navigate>;
  }

  return children;
};

export default ProtectedRoute;
