import * as actions from "../utils/action";

const intitialState = {
  REFERRAL: [],
  Success: false,
};

const REFERRAL_Reducer = (state, action) => {
  state = state || intitialState;

  switch (action.type) {
    case actions.SET_REFERRAL: {
      return Object.assign({}, state, {
        ...state,
        REFERRAL: action.payload,
        Success: false,
      });
    }

    case actions.SET_Referral_FILTERS: {
      return Object.assign({}, state, {
        ...state,
        filters: action.payload,
      });
    }

    case actions.ADD_REFERRAL: {
      return Object.assign({}, state, {
        ...state,
        Success: true,
      });
    }

    case actions.UPDATE_REFERRAL: {
      return Object.assign({}, state, {
        ...state,
        Success: true,
      });
    }

    default:
      return state;
  }
};

export default REFERRAL_Reducer;
