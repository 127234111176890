import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import logger from "redux-logger";
import rootReducer from "../reducers";

//NOT FOR PRODUCTION. ONLY LOCAL DEBUGGING

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk)) //logger
);
