import * as actions from "../utils/action";

const intitialState = {
  summeryReports: [],
};

const summeryReportsReducer = (state, action) => {
  state = state || intitialState;

  switch (action.type) {
    case actions.SET_SUMMERY_REPORTS: {
      return Object.assign({}, state, {
        ...state,
        summeryReports: action.payload,
      });
    }
    case actions.SET_DOCTOR_PROVIDERS: {
      return Object.assign({}, state, {
        ...state,
        DOCTOR_PROVIDERS: action.payload,
      });
    }
    default:
      return state;
  }
};

export default summeryReportsReducer;
