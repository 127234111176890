import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loaderReducer from "./loaderReducer";
import reportsReducer from "./reportsReducer";
import summeryReportsReducer from "./summeryReportsReducer";
import REFERRAL_Reducer from "./refferralReducer";
import * as actions from "../utils/action";
const appReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  reports: reportsReducer,
  summeryReports: summeryReportsReducer,
  referral: REFERRAL_Reducer,
});

const rootReducer = (state, action) => {
  if (
    action.type === actions.LOGIN_LOGOUT ||
    action.type === actions.LOGIN_FAIL
  ) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
