import * as actions from "../utils/action";

const intitialState = {
  loader: false,
};

const loaderReducer = (state, action) => {
  state = state || intitialState;

  switch (action.type) {
    case actions.LOADER_START: {
      return Object.assign({}, state, {
        ...state,
        loader: true,
      });
    }
    case actions.LOADER_STOP: {
      return Object.assign({}, state, {
        ...state,
        loader: false,
      });
    }

    default:
      return state;
  }
};

export default loaderReducer;
