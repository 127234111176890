export const STORAGE_TOKEN_KEY = "_token";
export const STORAGE_PROFILE_KEY = "_profile";
export const COMMON_SERVICE_AUTH_TOKKEN = "_common_service_token";

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength4 = minLength(4);

// theme constents
export const LIGHT_MODE = "light-mode";
export const DARK_MODE = "dark-mode";

export const SIDEBAR_LAYOUT = "sidenav-toggled";
